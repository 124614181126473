import { makeStyles } from '@mui/styles';
// assets
import langString from 'utils/langString';
import { IconBell } from '@tabler/icons';
import TextsmsIcon from '@mui/icons-material/Textsms';
import SendIcon from '@mui/icons-material/Send';

const useStyles = makeStyles({
    root: {
        '& .MuiTabs-indicator': {
            backgroundColor: '#fff'
        }
    }
});

export const tabStyles = (theme: any) => ({
    mb: 3,
    '& a': {
        minHeight: 'auto',
        minWidth: 10,
        py: 1.5,
        px: 1,
        mr: 2.25,
        color: theme.palette.grey[600],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    '& a.Mui-selected': {
        color: theme.palette.primary.main
    },
    '& .MuiTabs-indicator': {
        bottom: 2
    },
    '& a > svg': {
        marginBottom: '0px !important',
        mr: 1.25
    }
});

export const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '10px !important',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    overflow: 'auto'
};

export const tabsOption = [
    {
        label: langString('All'),
        icon: <IconBell />
    },
    {
        label: langString('Published Notification'),
        icon: <TextsmsIcon />
    },
    {
        label: langString('Draft Notification'),
        icon: <SendIcon />
    }
];

export default useStyles;
